/* eslint-disable no-case-declarations */
/* eslint-disable indent */
import { INotificationContext } from '@/types'
import { Notification, NotificationType } from 'api/notifications'
import { TagTheme } from 'components/notifications/NotificationTags'
import { Currencies, Currency } from 'constants/currencies'
import { TypeToContentMap } from 'constants/notifications'
import routes from 'constants/routes'
import {
  DOCUMENT_STATUS,
  NOTIFICATION_STATUS,
  SHIPMENT_STATUS,
} from 'constants/statuses'
import { useCurrencies } from 'contexts/hooks/useCurrencies'
import dayjs from 'dayjs'
import { Translate } from 'next-translate'
import { pickPrice } from 'utils/currencies'
import { formatLocalDate } from 'utils/dates'
import { getLink } from 'utils/strings'
import {
  getArtistUrl,
  getArtworkUrl,
  getArtworkUrlFromRoot,
  getBuyNowUrl,
  getEditAskUrl,
  getEditBidUrl,
  getOrderUrl,
  getSaleUrl,
  getSellNowUrl,
  getSubmitDocumentsUrl,
} from 'utils/urls'

export function getNotificationPrice(
  userCurrency: Currency,
  context?: INotificationContext
) {
  if (!context) {
    return null
  }

  const { currency, price, priceConvertedToGbp, priceConvertedToUsd } = context

  return pickPrice({
    priceCurrency: currency as Currency,
    price,
    priceGbp: priceConvertedToGbp,
    priceUsd: priceConvertedToUsd,
    userCurrency,
  })
}

export function determineNotificationPrices(
  userCurrency: Currency,
  notification: Notification
) {
  const { context, artwork, hasAsk, hasBid } = notification

  const price = getNotificationPrice(userCurrency, context)
  const mainEdition = artwork?.editions?.find(e => e.name === 'Main')

  const highestBid = mainEdition?.highestBid
  const highestBidPrice = highestBid
    ? pickPrice({
        userCurrency,
        priceCurrency: highestBid.currency,
        price: highestBid.price,
        priceGbp: highestBid.priceConvertedToGbp,
        priceUsd: highestBid.priceConvertedToUsd,
      })
    : null

  const lowestAsk = mainEdition?.lowestAsk
  const lowestAskPrice = lowestAsk
    ? pickPrice({
        userCurrency,
        priceCurrency: lowestAsk.currency,
        price: lowestAsk.price,
        priceGbp: lowestAsk.priceConvertedToGbp,
        priceUsd: lowestAsk.priceConvertedToUsd,
      })
    : null

  const ownBidPrice = hasBid
    ? pickPrice({
        userCurrency,
        priceCurrency: hasBid.currency,
        price: hasBid.price,
        priceGbp: hasBid.priceConvertedToGbp,
        priceUsd: hasBid.priceConvertedToUsd,
      })
    : null

  const onwAskPrice = hasAsk
    ? pickPrice({
        userCurrency,
        priceCurrency: hasAsk.currency,
        price: hasAsk.price,
        priceGbp: hasAsk.priceConvertedToGbp,
        priceUsd: hasAsk.priceConvertedToUsd,
      })
    : null

  return {
    price,
    highestBidPrice,
    lowestAskPrice,
    lowestAskPriceGbp: lowestAsk?.priceGbp,
    ownBidPrice,
    onwAskPrice,
  }
}

/**
 * The current status of a sale notification, depending on the context (auth status, auth deadline, etc.).
 */
export interface NotificationStatusInfo {
  /**
   * The determined status of the sale notification.
   */
  status?: string
  /**
   * The deadline date for an action.
   */
  deadlineDate?: dayjs.Dayjs
  /**
   * The number of days until the deadline for an action.
   */
  deadlineDays?: number
}

/**
 * Determines the status, deadline date and how many days until the deadline for a SALE notification, depending on multiple fields.
 */
export function determineSaleNotificationStatusInfo(
  notification: Notification
): NotificationStatusInfo {
  if (notification.type !== NotificationType.SALE || !notification.context)
    return {}

  const { documentDeadline, documentStatus, shippingDeadline, shippingStatus } =
    notification.context

  const currentDate = dayjs()

  if (documentStatus === DOCUMENT_STATUS.submitted) {
    return { status: NOTIFICATION_STATUS.AUTH_REVIEW }
  }

  // documents are required, the user should upload them
  if (documentStatus === DOCUMENT_STATUS.required && documentDeadline) {
    const authDeadlineDate = dayjs(documentDeadline)
    const authDeadlineDays = currentDate.businessDaysDiff(authDeadlineDate) - 1
    const authOverdue =
      typeof authDeadlineDays === 'number' && authDeadlineDays < 0

    if (!authOverdue) {
      return {
        status: NOTIFICATION_STATUS.AUTH_REQUIRED,
        deadlineDate: authDeadlineDate,
        deadlineDays: authDeadlineDays,
      }
    }

    if (authOverdue) {
      return {
        status: NOTIFICATION_STATUS.AUTH_OVERDUE,
        deadlineDate: authDeadlineDate,
        deadlineDays: authDeadlineDays,
      }
    }
  }

  // documents are not required, the user should send the artwork
  if (
    (documentStatus === DOCUMENT_STATUS.not_required ||
      documentStatus === DOCUMENT_STATUS.approved) &&
    shippingStatus === SHIPMENT_STATUS.READY_TO_SEND &&
    shippingDeadline
  ) {
    const shippingDeadlineDate = dayjs(shippingDeadline)
    const shippingDeadlineDays =
      currentDate.businessDaysDiff(shippingDeadlineDate) - 1
    const shippingOverdue =
      typeof shippingDeadlineDays === 'number' && shippingDeadlineDays < 0

    if (!shippingOverdue) {
      return {
        status:
          documentStatus === DOCUMENT_STATUS.approved
            ? NOTIFICATION_STATUS.AUTH_APPROVED
            : NOTIFICATION_STATUS.SHOULD_SHIP,
        deadlineDate: shippingDeadlineDate,
        deadlineDays: shippingDeadlineDays,
      }
    }

    if (shippingOverdue) {
      return {
        status: NOTIFICATION_STATUS.SHIPPING_OVERDUE,
        deadlineDate: shippingDeadlineDate,
        deadlineDays: shippingDeadlineDays,
      }
    }
  }

  return {}
}

function determineDeadlineTag(
  deadlineDays: number | undefined,
  labels: { today: string; tomorrow: string; future: string }
) {
  if (typeof deadlineDays === 'undefined') return undefined
  if (deadlineDays <= 0) return labels.today
  if (deadlineDays === 1) return labels.tomorrow
  return labels.future
}

function determineShipTag(deadlineDays: number | undefined) {
  return determineDeadlineTag(deadlineDays, {
    today: 'ship_today',
    tomorrow: 'ship_by_tomorrow',
    future: 'ship_within_x_days',
  })
}

function determineDocsTag(deadlineDays: number | undefined) {
  return determineDeadlineTag(deadlineDays, {
    today: 'add_docs_today',
    tomorrow: 'add_docs_by_tomorrow',
    future: 'add_docs_within_x_days',
  })
}

/**
 * The additional information that appears when a notification is extended (when the user is on the notifications page and clicks one of the notifications).
 */
export interface NotificationInfo {
  /**
   * The title of the information.
   */
  title?: string
  /**
   * The title which should be displayed only on mobile devices.
   */
  titleMobile?: string
  /**
   * The description of the information.
   */
  description?: string
  /**
   * The description which should be displayed only on mobile devices.
   */
  descriptionMobile?: string
  /**
   * The translation payload that should be passed to both the title and description. Here some values might be injected so they don't need to be computed again when displaying the elements.
   */
  translationPayload?: Record<string, string | number | undefined>
}

export function determineSaleNotificationContent(notification: Notification): {
  title?: string
  tag?: string
  tagTheme?: TagTheme
  translationPayload?: Record<string, string | number | undefined>
  isDanger?: boolean
  info?: NotificationInfo
  statusInfo?: NotificationStatusInfo
  secondaryAction?: string
} {
  if (!notification.context) return {}

  const statusInfo = determineSaleNotificationStatusInfo(notification)
  const { deadlineDate, deadlineDays, status } = statusInfo
  const deadline = deadlineDate ? formatLocalDate(deadlineDate) : undefined
  const absDeadlineDays = deadlineDays ? Math.abs(deadlineDays) : undefined

  switch (status) {
    case NOTIFICATION_STATUS.AUTH_REQUIRED:
      return {
        title: 'your_item_sold_upload_auth',
        tag: determineDocsTag(deadlineDays),
        tagTheme: 'red',
        translationPayload: { deadlineDays: absDeadlineDays },
        info: {
          title: 'auth_required',
          description: 'to_complete_sale',
          translationPayload: {
            deadline,
          },
          descriptionMobile: 'to_complete_sale',
        },
        statusInfo,
        secondaryAction: 'upload_docs',
      }

    case NOTIFICATION_STATUS.AUTH_OVERDUE:
      return {
        title: 'auth_overdue',
        tag: 'x_days_overdue',
        tagTheme: 'red',
        isDanger: true,
        translationPayload: {
          overdueDays: absDeadlineDays,
        },
        info: {
          title: 'auth_overdue',
          description: 'auth_for_this_sale_is_overdue',
          titleMobile: 'last_chance',
          descriptionMobile: 'please_submit_required_documents_immediately',
        },
        statusInfo,
        secondaryAction: 'upload_docs',
      }

    case NOTIFICATION_STATUS.AUTH_REVIEW:
      return {
        title: 'sale_auth_review',
        tag: 'authentication',
        tagTheme: 'yellow',
        info: {
          title: 'auth_review',
          description: 'our_team_is_reviewing_auth_docs',
          descriptionMobile: 'our_team_is_reviewing_auth_docs',
        },
        statusInfo,
      }

    case NOTIFICATION_STATUS.AUTH_APPROVED:
      return {
        title: 'auth_approved_ship_now',
        tag: determineShipTag(deadlineDays),
        tagTheme: 'red',
        translationPayload: { deadlineDays: absDeadlineDays },
        info: {
          title: 'auth_approved_ship_now_to_get_paid',
          description: 'please_check_email_for_shipping_instructions',
          titleMobile: 'ship_by_deadline',
          descriptionMobile: 'please_check_your_email_for_packing_guidance',
          translationPayload: {
            deadline,
          },
        },
        statusInfo,
        secondaryAction: 'print_label',
      }

    case NOTIFICATION_STATUS.SHOULD_SHIP:
      return {
        title: 'your_item_sold_ship_now',
        tag: determineShipTag(deadlineDays),
        tagTheme: 'red',
        translationPayload: { deadlineDays: absDeadlineDays },
        info: {
          title: 'your_item_sold_ship_now_to_get_paid',
          description: 'please_check_email_for_shipping_instructions',
          titleMobile: 'ship_by_deadline',
          descriptionMobile: 'please_check_your_email_for_packing_guidance',
        },
        statusInfo,
        secondaryAction: 'print_label',
      }

    case NOTIFICATION_STATUS.SHIPPING_OVERDUE:
      return {
        title: 'shipping_overdue',
        tag: 'x_days_overdue',
        tagTheme: 'red',
        isDanger: true,
        translationPayload: {
          overdueDays: absDeadlineDays,
        },
        info: {
          title: 'shipping_overdue',
          description: 'shipping_for_this_sale_is_overdue',
          titleMobile: 'last_chance',
          descriptionMobile: 'please_ship_the_item_sold_immediately',
        },
        statusInfo,
        secondaryAction: 'print_label',
      }

    default:
      // the user should neither send the artwork, nor upload any documents
      return { title: 'your_item_sold' }
  }
}

export const getNotificationContent = (
  userCurrency: Currency,
  notification: Notification,
  t: Translate,
  formatCurrency: ReturnType<typeof useCurrencies>['formatCurrency']
) => {
  const { type, groupingCount, artwork, context, hasAsk, hasBid, parentId } =
    notification

  const content = TypeToContentMap[type] ?? {}
  let { title, action, secondaryAction } = content
  const { description, actionTheme, secondaryActionTheme } = content
  let tag = null
  let tagTheme = null
  let isDanger = false
  let info: NotificationInfo | null | undefined = null
  let statusInfo: NotificationStatusInfo | null | undefined = null

  const isNotificationGrouped = Boolean(groupingCount && groupingCount > 1)

  const artworkName = artwork?.name || ''
  const artistName =
    artwork?.artists?.map(artist => artist.name).join(' x ') || ''

  const {
    price,
    highestBidPrice,
    lowestAskPrice,
    lowestAskPriceGbp,
    onwAskPrice,
    ownBidPrice,
  } = determineNotificationPrices(userCurrency, notification)

  const formatPrice = (value: number) =>
    formatCurrency(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      from: Currencies.USER,
      to: Currencies.USER,
    })

  let translationPayload = {
    price: formatPrice(price || 0),
    highestBid: highestBidPrice ? formatPrice(highestBidPrice / 100) : '',
    lowestAsk: lowestAskPrice ? formatPrice(lowestAskPrice / 100) : '',
    ownBid: ownBidPrice ? formatPrice(ownBidPrice / 100) : '',
    ownAsk: onwAskPrice ? formatPrice(onwAskPrice / 100) : '',
    nrAsks: context?.nrAsks || '',
    nrBids: context?.nrBids || '',
  }

  switch (type) {
    case NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW:
    case NotificationType.NEW_LOWEST_ASK_SAVED_ARTWORK:
    case NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST:
      // don't show the "buy now" button if the current lowest ask does not have the same value as in the context
      if ((lowestAskPriceGbp || 0) / 100 !== context?.priceGbp) {
        action = undefined
      }
      if (isNotificationGrouped) {
        title = 'x_new_lowest_listings'
      }
      break

    // don't show the "buy now" button if the artwork does not have a lowest ask because the user cannot sell the item
    case NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW: // kept for backwards compatibility
    case NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST:
    case NotificationType.NEW_HIGHEST_BID_SAVED_ARTWORK:
      if (!lowestAskPrice) {
        action = undefined
      }
      break

    case NotificationType.NEW_HIGHEST_BID_BUYER:
    case NotificationType.NEW_LOWEST_ASK_BUYER:
      if (!hasBid?.price) {
        action = undefined
      }

      if (!lowestAskPrice) {
        secondaryAction = undefined
      }
      break

    // don't show the "sell now" button if the artwork does not have a highest bid because the user cannot buy the item
    case NotificationType.NEW_HIGHEST_BID_SELLER:
    case NotificationType.NEW_LOWEST_ASK_SELLER:
      if (!hasAsk?.price) {
        action = undefined
      }

      if (!highestBidPrice) {
        secondaryAction = undefined
      }
      break

    case NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED:
      if (parentId) {
        title = 'new_highest_offer'
      }
      break

    case NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED:
      if (parentId) {
        title = 'new_lowest_listing'
      }
      break

    case NotificationType.SALE:
      // eslint-disable-next-line no-case-declarations
      const saleNotificationContent =
        determineSaleNotificationContent(notification)

      if (saleNotificationContent.title) {
        title = saleNotificationContent.title
      }

      if (saleNotificationContent.translationPayload) {
        translationPayload = {
          ...translationPayload,
          ...saleNotificationContent.translationPayload,
        }
      }

      tag = saleNotificationContent.tag
      tagTheme = saleNotificationContent.tagTheme
      isDanger = Boolean(saleNotificationContent.isDanger)
      info = saleNotificationContent.info
      statusInfo = saleNotificationContent.statusInfo
      secondaryAction = saleNotificationContent.secondaryAction

      break
  }

  return {
    title: t(title, translationPayload),
    action: action ? t(action, translationPayload) : undefined,
    secondaryAction: secondaryAction
      ? t(secondaryAction, translationPayload)
      : undefined,
    description: description
      ? t(description, { artworkName, artistName, price })
      : undefined,
    actionTheme,
    secondaryActionTheme,
    tag: tag ? t(tag, translationPayload) : undefined,
    tagTheme,
    isDanger,
    info,
    statusInfo,
  }
}

export const getActionsBehavior = (
  props: Notification
): { primary: string; secondary?: string } => {
  const { context, type, artwork, groupingCount } = props

  const defaultReturn = { primary: '#' }

  if (!context || !type || !artwork) return defaultReturn

  const { artists = [], name: artworkName = '', id: artworkId } = artwork
  const { askId, bidId, orderId, dhlInsurance } = context

  switch (type) {
    // upload auth docs
    case NotificationType.SALE_CONFIRMATION_PASS_AUTH: {
      return {
        primary: getArtworkUrlFromRoot(
          'ask-or-sell/submit-documents',
          artists,
          artworkName,
          artworkId
        ),
      }
    }

    // view order details
    case NotificationType.ORDER_CONFIRMATION: {
      if (!askId || !bidId || !orderId) return defaultReturn
      return { primary: getOrderUrl(artworkId, askId, bidId, orderId) }
    }

    // view sale details
    case NotificationType.SALE_CONFIRMATION:
    case NotificationType.SALE: {
      if (!askId || !bidId || !orderId) return defaultReturn

      const { status } = determineSaleNotificationStatusInfo(props)

      let secondary = undefined

      switch (status) {
        case NOTIFICATION_STATUS.AUTH_APPROVED:
        case NOTIFICATION_STATUS.SHOULD_SHIP:
        case NOTIFICATION_STATUS.SHIPPING_OVERDUE:
          secondary = getLink(context.shipmentLabelUrl || context.shipmentUrl)
          break
        case NOTIFICATION_STATUS.AUTH_OVERDUE:
        case NOTIFICATION_STATUS.AUTH_REQUIRED:
          secondary = getSubmitDocumentsUrl(
            artists,
            artworkName,
            artworkId,
            askId
          )
          break
      }

      return {
        primary: getSaleUrl(artworkId, askId, bidId, orderId),
        secondary,
      }
    }

    // update listing
    case NotificationType.SALE_BY_ANOTHER_SELLER:
    case NotificationType.NEW_HIGHEST_BID_SELLER:
    case NotificationType.NEW_LOWEST_ASK_SELLER: {
      return {
        primary: getEditAskUrl(artists, artworkName, artworkId),
        secondary: getSellNowUrl(artists, artworkName, artworkId, dhlInsurance),
      }
    }

    // update offer
    case NotificationType.ORDER_BY_ANOTHER_BUYER:
    case NotificationType.NEW_HIGHEST_BID_BUYER:
    case NotificationType.NEW_LOWEST_ASK_BUYER: {
      return {
        primary: getEditBidUrl(artists, artworkName, artworkId),
        secondary: getBuyNowUrl(artists, artworkName, artworkId),
      }
    }

    // view bid/view bid
    case NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW:
    case NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW: {
      const artworkUrl = getArtworkUrl(artists, artworkName, artworkId)

      if (groupingCount && groupingCount > 0) {
        const artist = artists[0]
        if (!artist) return { primary: artworkUrl }
        return {
          primary: getArtistUrl(artist.name, artist.id) + '?sort=Lowest+Ask',
        }
      }

      return { primary: artworkUrl }
    }

    // extend offer
    case NotificationType.BID_EXPIRED_1day:
    case NotificationType.BID_EXPIRED_7days: {
      return { primary: routes.myAccount.buying }
    }

    // extend listing
    case NotificationType.ASK_EXPIRED_1day:
    case NotificationType.ASK_EXPIRED_7days: {
      return { primary: routes.myAccount.selling }
    }

    // renew offer
    case NotificationType.BID_EXPIRED: {
      return { primary: `${routes.myAccount.buying}?tab=expired_offers` }
    }

    // renew listing
    case NotificationType.ASK_EXPIRED: {
      return { primary: `${routes.myAccount.selling}?tab=expired_listings` }
    }

    default:
      return { primary: '#' }
  }
}

export function isFollowedArtistNotification(type: NotificationType) {
  return (
    type === NotificationType.NEW_SALE_FOLLOW_ARTIST ||
    type === NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW ||
    type === NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW ||
    type === NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST ||
    type === NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST ||
    type === NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED ||
    type === NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED
  )
}

export function isSavedArtworkNotification(type: NotificationType) {
  return (
    type === NotificationType.NEW_SALE_SAVE_ARTWORK ||
    type === NotificationType.NEW_LOWEST_ASK_SAVED_ARTWORK ||
    type === NotificationType.NEW_HIGHEST_BID_SAVED_ARTWORK
  )
}

export function shouldDisplayArtistUrl(type: NotificationType) {
  return (
    type === NotificationType.NEW_SALE_FOLLOW_ARTIST ||
    type === NotificationType.NEW_SALE_SAVE_ARTWORK ||
    type === NotificationType.BID_EXPIRED ||
    type === NotificationType.ASK_EXPIRED ||
    type === NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW
  )
}

export function isArtistNotification(type: NotificationType) {
  return (
    type === NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED ||
    type === NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED
  )
}

export function isCombinedNotification(type: NotificationType) {
  return (
    type === NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED ||
    type === NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED
  )
}
